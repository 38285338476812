// =========================================/
// Header
// =========================================/

.header-container {
  background-repeat: repeat;
  box-shadow: 0px 0px 2px #d3d3d3;
  .hc-logos {
  	@include flex-container(space-between,center);
  	@include global-width(1050px);
  	padding: 5px 0px;
  	@include mq(s) {
  		display: block;
  	}
  	a {
  		height: 65px;
  		position: relative;
  		@include flex-container(center,center);
  		img {
  			height: 100%;
  			width: auto;
  			@include mq(m) {
  				height: 80%;
	  		}
	  		@include mq(s) {
	  			
	  		}
  		}
  		span {
  			visibility: hidden;
  			opacity: 0;
  			@include transition;
  			background-color: #333;
  			color: #fff;
  			padding: 15px;
  			border-radius: 3px;
  			position: absolute;
  			top: 110%;
  			min-width: 248px;
  			left: 0;
  			line-height: 1.5em;
  			strong {
  				font-weight: 600;
  			}
  		}
  		&:hover {
  			span {
  				visibility: visible;
  				opacity: 1;
  			}
  		}
  	}
  	a:nth-child(1) {
  		img {
  			height: 105%;
  			@include mq(m) {
  				height: 80%;
  			}
  		}
  	}
  	a:nth-child(2) {
  		img {
  			height: 90%;
  			@include mq(m) {
  				height: 75%;
	  		}
	  		@include mq(s) {
	  			
	  		}
  		}
  		span {
  			left: auto;
  			right: auto;
  		}
  	}
  	a:nth-child(3) {
  		span {
  			left: auto;
  			right: 0;
  		}
  	}
  	.desktop {
  		@include mq(s) {
  			display: none;
  		}
  	}
  	.mobile {
  		display: none;
  		@include mq(s) {
  			@include flex-container(center,center);
  		}
  		a {
  			margin-right: 30px;
  		}
  		a ~ a {
  			margin-right: 0px;
  		}
  	}
  }
  .hc-ribbon {
  	background-color: #f6f6f6;
  	.hcr-container {
  		@include global-width(1050px);
  		text-align: right;
  		padding: 8px 0px;
  		a {
  			font-weight: 500;
  			font-size: .7em;
  			margin-left: 20px;
  			color: #777;
  			text-transform: uppercase;
  			letter-spacing: 1px;
  			&:hover {
  				text-decoration: underline;
  			}
  			@include mq(l) {
  				display: none;
  			}
  		}
  		.phone {
  			font-weight: 600;
  			@include mq(l) {
  				display: block;
  				margin-left: 0px;
  				margin-bottom: 10px;
  			}
  			span {
  				font-weight: 400;
  				margin-right: 5px;
  			}
  		}
  		.phone ~ .phone {
  			@include mq(l) {
  				margin-bottom: 0px;
  			}
  		}
  		.hcrc-links {
  			@include mq(s) {
  				width: calc(100% - 40px);
  			}
  		}
  		@include mq(l) {
  			@include flex-container(space-between,center);
  			text-align: left;
  			a:nth-child(1) {
  				margin-left: 0px;
  			}
  		}
  		@include mq(s) {
  			a {
  				display: none;
  			}
  			a:nth-child(1), a:nth-child(2) {
  				display: inline-block;
  				font-size: 1em;
  			}
  		}
  	}
  }
}
.desktop-nav {
	background-color: $color-white;
	.desktop-nav-container {
		@include global-width(1050px);
		.dnc-label {
			padding: 13px 10px 10px;
			border-bottom: 3px solid rgba(0,0,0,0);
			margin-right: 10px;
			color: $color-black;
			display: inline-block;
			font-weight: 300;
			font-size: .95em;
			&:hover {
				border-bottom: 3px solid #d3d3d3;
			}
		}
		.dnc {
			display: inline-block;
			&:hover {
				.desktop-nav-sub {
					display: block;
				}
				.dnc-label {
					border-bottom: 3px solid #d3d3d3;
				}
			}
			a {
				padding: 13px 10px 10px;
		    border-bottom: 3px solid rgba(0, 0, 0, 0);
		    margin-right: 10px;
		    color: #333;
		    display: inline-block;
		    font-weight: 300;
		    font-size: .95em;
		    &:hover {
		    	border-bottom: 3px solid $color-secondary;
		    }
			}
		}
		.request-quote {
			float: right;
			margin: 0px;
			border-top: 3px solid $color-secondary;
			border-bottom: 3px solid $color-secondary !important;
			padding: 10px;
			@include transition;
			&:hover {
				background-color: $color-secondary;
				color: $color-white;
			}
		}
	}
	@include mq(l) {
		display: none;
	}
}
.desktop-nav-sub {
	position: absolute;
	width: 100%;
	background-color: #f6f6f6;
	left: 0;
	display: none;
	@include transition(.5s);
	.desktop-nav-sub-container {
		@include global-width(1050px);
	}
}

// =========================================/
// Header Mobile
// =========================================/

.mn-button {
	cursor: pointer;
	position: relative;
	z-index: 10;
	margin-top: 3px;
	display: none;
	@include mq(l) {
		display: block;
	}
	span {
		background-color: $color-black;
		height: 2px;
		width: 30px;
		display: block;
		margin-bottom: 5px;
		border-radius: 3px;
		transition: width 0.5s cubic-bezier(0.77,0.2,0.05,1.0), transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.3s ease;
	}
	span:nth-child(1) {
		transform: rotate(0deg) translate(0px, 0px);
  	transform-origin: 0px 3px;
	}
	span:nth-child(3) {
		transform: rotate(0deg) translate(0px, 0px);
		transform-origin: 0px -2px;
	}
}
.mn-button-rvs {
	span {
		background-color: $color-white;
	}
	span:nth-child(1) {
		transform: rotate(45deg) translate(-3px, -1px);
  	width: 25px;
	}
	span:nth-child(2) {
		opacity: 0;
	}
	span:nth-child(3) {
		transform: rotate(-45deg) translate(-3px, 0px);
		width: 25px;
	}
}
.mobile-nav-container {
	background-color: $color-black;
	width: 350px;
	position: fixed;
	top: 0;
	right: 0;
	height: 105%;
	box-shadow: 3px -5px 10px #000 inset;
	margin-right: -350px;
	-webkit-transition: margin-right 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	-moz-transition: margin-right 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	-ms-transition: margin-right 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	-o-transition: margin-right 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	transition: margin-right 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	padding: 100px 20px;
	z-index: 5;
	overflow-y: scroll;
	.mnc-background {
		background-image: url(/static/media/brilliant.png);
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
	a {
		display: block;
		color: $color-white;
		font-size: 20px;
		margin-bottom: 30px;
		position: relative;
	}
	.mnc-light {
		opacity: .5;
	}
	hr {
		border-top: 1px solid gray;
	}
}
.mobile-nav-container-open {
	margin-right: 0px;
}
.body-shift {
	margin-left: -350px;
	margin-right: 350px;
	overflow: hidden;
}