// =========================================/
// Home
// =========================================/

.home-banner {
	position: relative;
	z-index: -2;
	@include animation(homeBanner, .5s, ease-out);
	.hb-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		@include transition(2s, background-image);
	}
	.hb-content {
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.3);
		position: relative;
		@include flex-container(center,center);
		h1 {
			text-align: center;
	    font-weight: 600;
	    color: #fff;
	    text-shadow: 1px 1px 2px #444;
	    font-size: 2.7em;
	    max-width: 900px;
	    line-height: 1.3em;
	    @include mq(m) {
	    	font-size: 2em;
	    }
			span {
				font-weight: 300;
				display: block;
				font-size: .9em;
			}
		}
	}
}
@keyframes homeBanner {
	0% {height: 300px;}
	100% {height: 500px;}
}

// =========================================/
// Form Defaults
// =========================================/

form {
	@include flex-container;
	width: 100%;
	label {
		.required {
			color: #a9a9a9;
			font-size: .8em;
			margin-left: 10px;
			font-style: italic;
		}
	}
	.checkbox {
		display: block;
		position: relative;
		padding-left: 35px;
		margin: 20px 0px;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		width: 100%;
		label {
			margin-top: 0px !important;
		}
		input {
			position: absolute;
			opacity: 0;
			height: 0;
			width: 0;
		}
		.icon {
			position: absolute;
	    top: 0;
	    left: 0;
	    height: 25px;
	    width: 25px;
	    background-color: #eee;
	    cursor: pointer;
	    &:after {
				content: "";
				position: absolute;
				display: none;
				left: 9px;
			  top: 5px;
			  width: 5px;
			  height: 10px;
			  border: solid white;
			  border-width: 0 3px 3px 0;
			  -webkit-transform: rotate(45deg);
			  -ms-transform: rotate(45deg);
			  transform: rotate(45deg);
	    }
		}
		&:hover .icon {
			background-color: #ccc;
		}
	}
	.checkbox-active {
		.icon {
			background-color: $color-primary !important;
			&:after {
				display: block;
			}
		}
	}
}

// =========================================/
// Home - Quote
// =========================================/

.quote-bar {
	background-color: $color-primary;
	padding: 15px 0px;
	position: relative;
	z-index: 1;
	.qb-container {
		@include global-width(1050px);
		@include flex-container(space-between,center);
		height: 72px;
		@include animation(quoteFade, .8s);
		@include mq(l) {
			height: unset;
			@include flex-container(center);
		}
		p {
			color: $color-white;
	    width: 100%;
	    font-size: 1em;
	    margin-bottom: 10px;
	    @include mq(l) {
	    	width: unset;
	    	margin-bottom: 15px;
	    }
		}
		.hqc-form {
			@include flex-container(space-between,center);
			width: calc(100% - 180px);
			@include mq(l) {
				width: 100%;
				margin-bottom: 15px;
			}
			@include mq(m) {
				@include flex-container(center);
			}
			.hqc-type {
				width: calc(100% - 380px);
				position: relative;
				@include mq(m) {
					width: 370px;
					margin: 0px 0px 15px 10px;
				}
				.hqct-active {
					position: relative;
					background-color: $color-white;
					border-radius: 3px;
					color: $color-black;
					width: 100%;
					height: 40px;
					@include flex-container(space-between,center);
					padding: 7px;
					border: 1px solid #555;
					margin-bottom: 0px;
					span {

					}
					i {
						
					}
				}
				.hqct-container {
					position: absolute;
					border: 1px solid #555;
					background-color: $color-white;
					width: 100%;
					height: 100px;
					overflow-y: scroll;
					overflow-x: hidden;
					p {
						color: $color-black;
						padding: 5px;
						cursor: pointer;
						width: 100%;
						&:hover {
							background-color: #f6f6f6;
						}
					}
					.hqctc-active {
						font-weight: 600;
					}
				}
				.hqct-container-open {
					display: block;
				}
			}
			.hqc-first, .hqc-last {
				@include mq(m) {
					margin-bottom: 15px;
				}
			}
			input {
				height: 40px;
				width: 180px;
				border-radius: 3px;
				border: 1px solid #555;
				padding: 7px;
				margin-left: 10px;
			}
			.hqc-first {

			}
			.hqc-last {

			}
		}
		a {
			background-color: $color-white;
			text-transform: uppercase;
			padding: 11px 25px;
			font-weight: 600;
			border-radius: 3px;
			position: relative;
			margin-right: 0px;
			color: $color-primary;
			@include transition(.3s);
			i {
				display: none;
				position: absolute;
				right: 10px;
			}
			&:hover {
				padding: 11px 35px 11px 25px;
				margin-right: -10px;
				i {
					display: inline-block;
				}
			}
		}
	}
}
@keyframes quoteFade {
	0% {opacity: 0;}
	50% {opacity: 0;}
	100% {opacity: 1;}
}

// =========================================/
// Home - Individual/Business
// =========================================/

.home-ib {
	margin: 50px 0px;
	.home-ib-container {
		@include global-width(1050px);
		.hibc-image {
			position: relative;
			height: 400px;
			background-size: cover;
			background-position: center;
			@include flex-container(flex-end,center);
			.hibc-block {
				background-color: $color-white;
				width: 450px;
				max-width: 100%;
				padding: 30px;
				box-shadow: 0px 0px 40px #f2f2f2;
				border-radius: 10px;
				margin-right: -50px;
				@include mq(l) {
					margin: 0px !important;
					background-color: rgba(255,255,255,.9);
				}
				h3 {
					text-transform: uppercase;
					color: #777;
					font-weight: 400;
					letter-spacing: 2px;
				}
				p {
					font-size: 1.8em;
					line-height: 1.3em;
					margin: 20px 0px;
					font-weight: 300;
					@include mq(l) {
						font-size: 1.4em;
					}
				}
				a {
					text-transform: uppercase;
					padding: 10px 25px;
					font-weight: 600;
					border-radius: 3px;
					position: relative;
					margin-right: 0px;
					color: $color-secondary;
					border: 3px solid;
					@include transition(.3s);
					display: inline-block;
					i {
						display: none;
						position: absolute;
						right: 10px;
					}
					&:hover {
						padding: 10px 35px 10px 25px;
						margin-right: -10px;
						i {
							display: inline-block;
						}
					}
				}
			}
		}
		.hibc-blocks {
			@include flex-container(center);
			margin: 30px 0px;
			@include mq(m) {
				@include flex-container(space-between);
			}
			.hibcb {
				width: 11%;
				margin: 0px 2%;
				text-align: center;
				color: $color-primary;
				&:hover {
					.hibcb-block {
						border: 3px solid $color-primary;
					}
				}
				@include mq(l) {
					width: 14%;
					margin: 0px 1%;
					.hibcb-block {
						i {
							font-size: 2em;
						}
					}
				}
				@include mq(m) {
					flex: 1;
				}
				@include mq(s) {
					overflow: hidden;
				}
				.hibcb-block {
					border: 3px solid #d3d3d3;
					border-radius: 3px;
					@include flex-container(center,center);
					padding: 30px 0px;
					@include transition(.2s);
					i {
						font-size: 2.5em;
						color: #666;
					}
				}
				.hibcb-text {
					display: block;
					text-transform: uppercase;
					letter-spacing: 1px;
					margin: 10px 0px;
					font-size: .9em;
					line-height: 1.3em;
					@include mq(m) {
						font-size: .8em;
					}
				}
			}
		}
	}
}
.home-ib ~ .home-ib {
	.home-ib-container {
		.hibc-image {
			@include flex-container(flex-start,center);
			.hibc-block {
				margin-left: -50px;
			}
		}
	}
}

// =========================================/
// Home - About
// =========================================/

.home-about {
	.home-about-image {
		background-size: cover;
		background-position: center;
		background-attachment: fixed;
		height: 400px;
		.home-about-image-content {
			@include flex-container(center,center);
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,.3);
			h3 {
				color: $color-white;
				font-size: 2em;
				font-weight: 400;
				max-width: 95%;
				text-align: center;
				line-height: 1.4em;
				margin-bottom: 50px;
				text-shadow: 1px 1px 2px #444;
			}
		}
	}
	.home-about-container {
		@include global-width(1050px);
		margin: -100px auto 0px;
		.hac-blocks {
			@include flex-container(center);
			.hacb {
				width: 25%;
				text-align: center;
				margin: 0px 2%;
				@include mq(l) {
					width: 31.3%;
					margin: 0px 1%;
				}
				@include mq(m) {
					width: 49%;
					margin: 0px .5%;
				}
				@include mq(s) {
					width: 100%;
					margin: 0px;
				}
				.hacb-icon {
					background-color: $color-white;
					border-radius: 5px;
					@include flex-container(center,center);
					width: 150px;
					height: 150px;
					margin: auto;
					box-shadow: 0 0 24px 0 rgba(70,73,77,.1);
					i {
						font-size: 3em;
						color: #666;
					}
				}
				.hacb-content {
					h4 {
						margin: 30px 0px 20px;
						font-weight: 500;
						font-size: 1.2em;
					}
					p {
						font-size: 1em;
						line-height: 1.5em;
						font-weight: 300;
					}
				}
			}
		}
	}
}

// =========================================/
// Page - Default
// =========================================/

.page-default {
	.page-top {
		background-size: cover;
		background-position: center;
		@include animation(pageTopXLarge, .5s, ease-out);
		@include transition(2s, background-image);
		@include mq(xxl) {
			@include animation(pageTopLarge, .5s, ease-out);
		}
		@include mq(xl) {
			@include animation(pageTop, .5s, ease-out);
		}
		width: 100%;
		.pt-content {
			background-color: rgba(0,0,0,.2);
			height: 100%;
			.pt-container {
				@include global-width(1050px);
				display: block;
				height: 100%;
				.ptc-text-container {
					@include flex-container(flex-start,center);
					height: 100%;
					h1 {
						color: $color-white;
						font-size: 1.8em;
						line-height: 1.4em;
						font-weight: 400;
						text-shadow: 1px 1px 1.2px #444;
					}
				}
			}
		}
	}
	.page-default-container {
		@include global-width(1050px, 30px auto);
		.pdc-middle-images {
			@include flex-container;
			background-color: $color-primary;
			padding: 20px;
			margin-bottom: 20px;
			box-shadow: 3px 3px 10px #a9a9a9;
			.pdcmi-left {
				width: 70%;
				figure {
					img {
						width: 100%;
					}
				}
				@include mq(l) {
					width: 100%;
				}
			}
			.pdcmi-right {
				width: 28%;
				figure:first-child {
					margin-bottom: 10px;
				}
				@include mq(l) {
					width: 100%;
					margin-top: 10px;
				}
			}
			figcaption {
				color: #f2f2f2;
				font-size: .9em;
				margin-top: 10px;
				font-style: italic;
			}
		}
	}
	.pd-title {
		font-size: 1.8em;
		line-height: 1.4em;
    font-weight: 600;
    margin: 0px 0px 30px;
    padding-top: 30px;
	}
	.page-container {
		@include global-width(1050px, 30px auto 0px);
		@include flex-container(space-between);
		.page-main {
			width: calc(100% - 350px);
			@include mq(l) {
				width: 100%;
			}
			.pm-links {
				display: inline-block;
				a {
					font-weight: 600;
					margin: 0px 10px;
				}
			}
			h2 {
				font-size: 1.8em;
				line-height: 1.4em;
		    font-weight: 600;
		    margin: 0px 0px 30px;
		    padding-top: 30px;
			}
			h3 {
				font-size: 1.4em;
				line-height: 1.4em;
		    font-weight: 600;
		    margin: 30px 0px 20px;
			}
		}
		.page-sidebar {
			width: 300px;
			position: relative;
			@include mq(l) {
				width: 100%;
			}
			.page-side-blocks {
				width: 300px;
				@include mq(l) {
					position: relative !important;
					width: 100%;
					@include flex-container(center);
				}
				.psb {
					background-color: #f6f6f6;
					padding: 20px 30px;
					border-radius: 5px;
					margin: 0px 0px 15px;
					text-align: center;
					border: 2px solid #333;
					@include mq(l) {
						width: 48%;
						margin: 1%;
					}
					@include mq(s) {
						width: 100%;
						margin: 1% 0px;
					}
					p {
						font-size: 1.2em;
						line-height: 1.5em;
					}
					.phone {
						font-size: 1.4em;
						font-weight: 600;
						display: block;
						margin-bottom: 10px;
						span {
							display: block;
							color: #333;
							font-size: .7em;
							font-weight: 400;
							font-style: italic;
							margin-bottom: 5px;
						}
					}
					.phone ~ .phone {
						margin-bottom: 0px;
					}
					.button {
						background-color: $color-white;
						padding: 10px 18px;
						border-radius: 3px;
						position: relative;
						text-transform: uppercase;
						font-size: .9em;
						letter-spacing: .5px;
						@include transition(.3s);
						display: inline-block;
						i {
							display: none;
							position: absolute;
							right: 10px;
						}
						&:hover {
							padding: 10px 28px 10px 18px;
							margin-right: -10px;
							i {
								display: inline-block;
							}
						}
					}
				}
				.psb:nth-child(even) {
					background-color: $color-secondary;
					color: $color-white;
					border: 2px solid $color-secondary;
				}
				img {
					display: block;
					margin: 40px auto;
				}
			}
			.psb-fixed {
				position: fixed;
				top: 40px;
			}
			.psb-bottom {
				position: absolute;
				bottom: 0;
			}
		}
	}
}
@keyframes pageTop {
	0% {height: 150px;}
	100% {height: 300px;}
}
@keyframes pageTopLarge {
	0% {height: 250px;}
	100% {height: 400px;}
}
@keyframes pageTopXLarge {
	0% {height: 350px;}
	100% {height: 500px;}
}
.pm-breadcrumbs {
	display: inline-block;
	margin: 0px;
	font-size: 1em;
	a {
		&:hover {
			text-decoration: underline;
		}
	}
	span {
		margin: 0px 5px;
	}
	.page-link {
		margin-left: 30px;
		color: $color-primary;
		font-weight: 600;
		&:hover {
			text-decoration: underline;
		}
	}
	.page-link ~ .page-link {
		margin-left: 15px;
	}
}

// =========================================/
// Staff
// =========================================/

.staff {
	@include flex-container(flex-start);
	margin-top: 30px;
	.agent {
		width: 24%;
		margin: 0 .5%;
		@include mq(l) {
			width: 32%;
		}
		@include mq(m) {
			width: 49%;
		}
		@include mq(s) {
			width: 100%;
		}
		img {
			width: 100%;
			margin-bottom: 10px;
		}
		p, a {
			font-size: 1em;
			line-height: 1.3em;
			margin-bottom: .8em;
			display: block;
		}
		p:nth-child(2) {
			font-weight: 600;
			font-size: 1.1em;
		}
		p:nth-child(3) {
			font-style: italic;
			color: gray;
		}
		a:nth-child(4) {

		}
		a:nth-child(5) {

		}
	}
}

// =========================================/
// Community
// =========================================/

.com-images {
	margin-top: 30px;
	.ci-img {
		@include flex-container(space-between,center);
		position: relative;
		margin-bottom: 30px;
		img {
			width: 50%;
			box-shadow: 3px 3px 10px #a9a9a9;
			z-index: 1;
			@include mq(m) {
				width: 100%;
			}
		}
		hr {
			position: absolute;
			top: 50%;
			width: 60%;
			left: 20%;
			border-top: 2px solid;
			@include mq(m) {
				position: relative;
				left: 0;
				top: 0;
				display: block;
				margin: 30px auto;
			}
		}
		.text {
			width: 50%;
			position: absolute;
			height: 100%;
			@include flex-container(center,center);
			p {
				color: $color-primary;
				font-size: 1.1em;
				font-style: italic;
			}
			@include mq(m) {
				position: relative;
				width: 100%;
			}
		}
	}
	.ci-img:nth-child(even) {
		img {
			margin-left: auto;
		}
	}
	.ci-img:nth-child(odd) {
		.text {
			right: 0;
		}
	}
}

// =========================================/
// Companies
// =========================================/

.companies {
	@include flex-container;
	margin-top: 30px;
	.company {
		width: 32.5%;
		background-color: #f2f2f2;
		border-radius: 3px;
		height: 200px;
		@include flex-container(center,center);
		margin: 5px 0px;
		@include mq(m) {
			width: 49%;
		}
		@include mq(s) {
			width: 100%;
		}
		img {
			max-width: 80%;
			max-height: 80%;
			mix-blend-mode: multiply;
		}
	}
}

// =========================================/
// Payments and Claims
// =========================================/

.payments-table {
	box-shadow: unset;
	border-radius: unset;
	overflow: unset;
	tr:nth-child(2) {
		td {
			color: $color-primary;
			font-weight: 600;
			background-color: #f2f2f2;
		}
	}
	td {
		padding: 20px;
		@include mq(m) {
			padding: 10px !important;
		}
		img {
			width: 200px;
			mix-blend-mode: multiply;
		}
		a {
			color: $color-black;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	td:nth-child(1) {
		@include mq(m) {
			display: none;
		}
	}
}

// =========================================/
// Page - Quote
// =========================================/

.quote-header {
	.qh-container {
		@include global-width;
		margin: 50px auto;
		text-align: center;
		position: relative;
		img {

		}
		i {
			position: absolute;
			top: 0;
			right: 0;
			font-size: 2em;
			cursor: pointer;
			margin: 15px;
			color: #a9a9a9;
			&:hover {
				color: #555;
			}
		}
	}
}
.page-quote {
	position: relative;
	.page-back {
		position: absolute;
		top: 0;
		right: 0;
		padding: 30px;
		font-size: 2em;
		cursor: pointer;
	}
	.page-quote-container {
		@include global-width(800px);
		h1 {
			font-style: italic;
	    font-size: 1.8em;
	    text-align: center;
	    margin-bottom: 30px;
			span {
				font-style: normal;
    		font-weight: 700;
			}
		}
		.pqc-sub-header {
			font-size: 1.2em;
			text-align: center;
		}
		.user-text {
			margin-bottom: 30px;
		}
		form {
			.quote-form-section {
				margin-bottom: 50px;
				opacity: 0;
				overflow: hidden;
				@include transition(.5s, opacity);
				@include flex-container;
				width: 100%;
				h2 {
					color: $color-secondary;
					font-size: 1.3em;
			    border-bottom: 2px solid;
			    padding-bottom: 20px;
			    margin-bottom: 15px;
			    font-weight: 600;
			    width: 100%;
				}
			}
			.quote-form-section-show {
				opacity: 1;
			}
			.form-additional-section {
				border-top: 2px solid $color-primary;
				margin-top: 60px;
				padding-top: 50px;
				@include flex-container;
			}
			.form-additional-button {
				color: $color-primary;
				font-weight: 600;
				cursor: pointer;
				margin-top: 20px;
				font-size: 1.1em;
				i {
					padding-left: 10px;
				}
				&:hover {
					text-decoration: underline;
				}
			}
			input {
				width: 100%;
				padding: 6px;
				margin-top: 3px;
				border: 1px solid #d3d3d3;
			}
			input[type=submit] {
				width: inherit;
			}
			label {
				display: block;
				font-size: 1em;
				margin-top: 10px;
			}
			select {
				width: 100%;
			  display: block;
			  padding: 6px;
			  height: 33px;
			  margin-top: 2px;
			  border-radius: 0px;
			}
			.input-double {
				@include flex-container;
				label {
					width: 49%;
					@include mq(s) {
						width: 100%;
					}
				}
			}
			.input-triple {
				@include flex-container;
				label {
					width: 32%;
					@include mq(s) {
						width: 100%;
					}
				}
			}
			.input-checkbox {
				@include flex-container(space-between,center);
				margin-top: 10px;
				input {
					width: 30px;
				}
				label {
					width: calc(100% - 30px);
					font-size: 15px;
					line-height: 20px;
				}
			}
			.select-multiple {
				height: 80px;
			}
			textarea {
				display: block;
		    width: 100%;
		    border: 1px solid #d3d3d3;
		    height: 80px;
		    padding: 6px;
		    margin-top: 3px;
			}
			input[type=submit] {
				width: 250px;
				display: block;
				margin: 30px auto;
				border: 2px solid $color-secondary;
				color: $color-secondary;
				padding: 8px;
				text-transform: uppercase;
				@include transition();
				font-weight: 600;
				letter-spacing: .5px;
				border-radius: 3px;
				&:hover {
					background-color: $color-secondary;
					color: $color-white;
				}
			}
			.quote-another-title {
				text-align: center;
				padding: 50px;
				span {
					display: block;
					font-size: 1.5em;
					font-weight: 600;
				}
			}
			.required-label {
				font-size: .8em;
				font-style: italic;
				display: inline-block;
				margin-left: 10px;
				color: $color-primary;
			}
		}
	}
}

// =========================================/
// Contact
// =========================================/

.page-contact {
	.contact-form-container {
		@include global-width(1050px, 50px auto);
		.pd-title {
			font-size: 1.8em;
			line-height: 1.4em;
	    font-weight: 600;
	    margin: 0px 0px 30px;
	    padding-top: 30px;
		}
		.map {
			margin-top: 0px;
		}
		form {
			@include flex-container;
			label {
				display: block;
				font-size: 14px;
				margin: 10px 0px 5px;
			}
			input {
				width: 100%;
				font-size: 14px;
				padding: 8px;
			}
			textarea {
				border: 1px solid #d3d3d3;
				height: 80px;
				width: 100%;
				padding: 8px;
				font-size: 14px;
			}
			input[type=submit] {
				width: 150px;
				border: 0px;
				background-color: #d3d3d3;
				border-radius: 3px;
				margin: 10px 0px 0px;
				&:hover {

				}
			}
			.form-section {
				@include flex-container;
				label {
					width: 100%;
				}
				input {
					width: 49%;
				}
			}
		}
	}
}

// =========================================/
// User Text Specific Formatting
// =========================================/

.user-text {
	width: 100%;
	max-width: 900px;
	h1 {
		font-size: 2em;
		line-height: 1.3em;
		margin: 30px 0px 20px;
	}
	h2 {
		font-size: 30px;
		line-height: 40px;
		margin: 30px 0px 20px;
	}
	h3 {
		font-size: 22px;
		line-height: 30px;
		margin: 25px 0px 15px;
		color: $color-primary;
	}
	p {
		font-size: 1em;
		line-height: 1.5em;
		margin-bottom: 1.2em;
	}
	li {
		font-size: 16px;
		line-height: 24px;
	}
	ul {
		margin-bottom: 20px;
		list-style: disc;
		padding-left: 40px;
	}
	ol {
		list-style-type: decimal;
		margin-bottom: 20px;
		padding-left: 40px;
	}
	a {
		font-weight: 500;
	}
	strong {
		font-weight: 900;
	}
	sup {
		vertical-align: super;
		font-size: .7em;
		line-height: 1em;
	}
	em {
		font-style: italic;
	}
}

// =========================================/
// Table Formatting
// =========================================/

table {
	max-width: 100%;
	margin: 40px 0px;
	box-shadow: 0px 0px 0px 2px $color-white, 0px 0px 0px 4px $color-primary;
	border-radius: 5px;
	overflow: hidden;
	tr {
		td {
			text-align: center;
			padding: 25px 10px;
			border: 1px solid #d3d3d3;
			border-bottom: 1px solid #d3d3d3;
			line-height: 24px;
			@include mq(m) {
				width: unset !important;
				white-space: unset !important;
				padding: 10px 2px !important;
				font-size: .8em !important;
				line-height: 1.5em !important;
			}
		}
		td:nth-child(1) {
			background-color: #f2f2f2;
			font-weight: 500;
		}
	}
	tr:nth-child(1) {
		td {
			background-color: $color-primary;
			color: $color-white;
			font-weight: 500;
			border: 0px;
		}
	}
	i {
		color: $color-secondary;
		font-size: 1.5em;
	}
}
.header-table {
	tr:nth-child(1) {
		td {
			background-color: $color-primary;
			color: $color-white;
			font-size: 24px;
			line-height: 32px;
			border: 0px;
		}
	}
	tr:not(:first-child) {
		td:nth-child(1) {
			background-color: $color-white;
			color: $color-black;
		}
		td {
			font-weight: 400;
			padding: 25px 40px;
			font-size: 16px;
			line-height: 24px;
			border: 0px;
			border-right: 1px solid #f2f2f2;
			@include mq(m) {
				padding: 25px 10px;
			}
		}
		td:last-child {
			border-right: 0px;
		}
	}
}
.table-space-between {
	tr:nth-child(1) {
		td {
			background-color: $color-primary;
			color: $color-white;
			font-size: 24px;
			line-height: 32px;
			border: 0px;
		}
	}
	tr:not(:first-child) {
		border-bottom: 1px solid #d3d3d3;
		td:nth-child(1) {
			background-color: #f2f2f2;
			color: $color-black;
			text-align: left;

		}
		td {
			font-weight: 400;
			padding: 25px 40px;
			font-size: 16px;
			line-height: 24px;
			border: 0px;
			border-right: 1px solid #f2f2f2;
			@include mq(m) {
				padding: 25px 10px;
			}
		}
		td:last-child {
			border-right: 0px;
			text-align: right;
		}
	}
}
.every-other-table {
	tr {
		td {
			font-weight: 400 !important;
			border: 1px solid #d3d3d3 !important;
		}
	}
	tr:nth-child(odd) {
		td {
			background-color: $color-primary;
			color: $color-white;
		}
	}
	tr:nth-child(even) {
		td {
			background-color: $color-white;
		}
	}
}
.table-evenly-spaced {
	tr {
		display: flex;
		width: 100%;
		td {
			flex: 1;
		}
	}
}
.table-align-left {
	tr:not(:first-child) {
		td {
			text-align: left;
		}
	}
}
.table-max-800 {
	width: 100%;
	max-width: 800px;
}
.table-max-900 {
	width: 100%;
	max-width: 900px;
}
.table-vert-headers {
	tr {
		td:nth-child(1) {
			width: max-content;
			white-space: nowrap;
		}
	}
}

// =========================================/
// Locations
// =========================================/

.location-content-container {
	@include flex-container;
	@include global-width(1050px, 50px auto);
	.locations-content {
		width: 300px;
		@include mq(m) {
			width: 100%;
		}
		.user-text {
			margin: 0px auto 50px;
		}
		.addresses {
			.addr {
				p {
					font-size: 1em;
					line-height: 1.5em;
					margin-bottom: 1em;
				}
				p:first-child {
					font-weight: 600;
				}
			}
		}
	}
	.map {
		width: calc(100% - 320px);
		height: 400px;
		box-shadow: 5px 5px 10px #a9a9a9;
		margin-top: 58px;
		@include mq(m) {
			width: 100%;
			margin-top: 50px;
		}
		i {
			font-size: 3em;
			transform: translate(-50%,-50%);
			color: $color-primary;
			filter: brightness(1.5);
		}
	}
}

// =========================================/
// Link Grids
// =========================================/

.link-grid {
	@include flex-container;
	margin: 50px 0px 0px;
	.lg {
		width: 32%;
		background-color: #f2f2f2;
		box-shadow: 1px 1px 5px #d3d3d3;
		padding: 30px;
		@include transition;
		@include flex-container(center,center);
		height: 150px;
		margin-bottom: 20px;
		position: relative;
		overflow: hidden;
		@include mq(m) {
			width: 49%;
		}
		@include mq(s) {
			width: 100%;
		}
		i {
			position: absolute;
			bottom: 0;
			right: 0;
			font-size: 10em;
			margin-right: -.2em;
			margin-bottom: -.2em;
			color: #d3d3d3;
			@include transition;
		}
		span {
			position: relative;
			z-index: 1;
			font-size: 1.5em;
			text-align: center;
			line-height: 1.3em;
			color: $color-primary;
			@include transition;
		}
		&:hover {
			box-shadow: 2px 2px 8px #a9a9a9;
			i {
				font-size: 10.3em;
			}
			span {
				margin-bottom: 3px;
				margin-right: 3px;
			}
		}
	}
}

// =========================================/
// Legal
// =========================================/

.page-legal-content {
	.user-text {
		@include global-width(800px, 50px auto);
	}
}

// =========================================/
// Internet Explorer Specific Styles
// =========================================/

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	// Styles
}