// =========================================/
// Global Admin
// =========================================/

.page-admin {
	background-color: $admin-color-bg-light;
	padding: 30px 0px;
	h1,h2,h3,h4,h5,h6 {
		font-family: $admin-header-font;
	}
	p,li,input,select,textarea {
		font-family: $admin-body-font;
	}
	a {
		color: $admin-color-primary;
		&:hover {
			color: $admin-color-primary;
			text-decoration: underline;
		}
	}
	label {
		font-size: 14px;
		font-weight: 900;
	}
	.breadcrumbs {
		.bc-active {
			margin: 0px;
		}
	}
	.pa-container {
		@include global-width;
	}
}

.admin-no-results {
	border-top: 1px dashed $admin-color-bg-medium;
	border-bottom: 1px dashed $admin-color-bg-medium;
	text-align: center;
	padding: 30px;
}
.show-input {
	display: block !important;
	width: 100%;
  padding: 8px;
  border: 1px solid $admin-color-bg-medium;
  margin: 5px 0px 10px;
}
.wrong-password {
	span {
		cursor: pointer;
		color: $color-primary;
		&:hover {
			text-decoration: underline;
		}
	}
}

// =========================================/
// Content
// =========================================/

.page-admin {
	.admin-content {
		width: 100%;
		.atbs-header {
			h2 {
				font-size: 2em;
				font-weight: 600;
				margin: 0px 0px 30px 30px;
			}
		}
		.ac-block {
			position: relative;
			width: 100%;
			.fa-times {
				position: absolute;
				top: 0;
				right: 0;
				font-size: 1.3em;
				padding: 10px;
				color: #a9a9a9;
				cursor: pointer;
				&:hover {
					color: #333;
				}
			}
			h2 {
				margin-bottom: .5em;
			}
			p {
				font-size: .9em;
				line-height: 1.5em;
				margin-bottom: 1em;
			}
			input {
				width: 100%;
				padding: 8px;
			}
			.dates-editor {
				@include flex-container;
				input {
					width: 49%;
				}
			}
			.select-images {
				@include transition;
				padding: 5px 15px;
				cursor: pointer;
				background-color: $color-primary;
				color: $color-white;
				border: 0px;
				border-radius: 3px;
				display: inline-block;
				margin: 10px 10px 0px 0px;
				&:hover {
					background-color: darken($color-primary, 5%);
				}
			}
			table {
				width: 100%;
		    margin: 30px 0px 0px;
		    border-radius: 0px;
		    box-shadow: none;
		    table-layout: fixed;
		    tr {
		    	border-top: 1px solid #d3d3d3;
		    	border-bottom: 1px solid #d3d3d3;
		    	border-left: 1px solid #d3d3d3;
		    }
		    td {
		    	background: none;
		    	color: #333;
		    	border-right: 1px solid #d3d3d3;
		    }
			}
		}
		input[type=submit] {
			@include transition;
			padding: 10px 20px;
			cursor: pointer;
			background-color: $color-secondary;
			color: $color-white;
			border: 0px;
			border-radius: 3px;
			display: inline-block;
			margin: 10px 10px 0px 0px;
			&:hover {
				background-color: darken($color-secondary, 5%);
			}
		}
	}
}

// =========================================/
// Content - New Section
// =========================================/

.page-admin {
	.admin-content {
		.ac-block {
			.acb-new-section {
				@include flex-container;
				input[type='text'], input[type='number'] {
					width: 49%;
				}
				div {
					width: 100%;
				}
				select {
					width: 49%;
					height: 40px;
					margin: 12px 0px;
				}
				.add-section-submit {
					display: inline-block;
					width: max-content;
					background-color: $color-primary;
					transition: all 0.1s;
					padding: 10px 20px;
					cursor: pointer;
					color: #fff;
					border: 0px;
					border-radius: 3px;
					display: inline-block;
					margin: 10px 10px 0px 0px;
					&:hover {
						background-color: darken($color-primary, 5%);
					}
				}
			}
		}
	}
}

// =========================================/
// Content - Quill Overrides
// =========================================/

.page-admin {
	.admin-content {
		.ac-block {
			.ql-container {
				font-size: inherit;
			}
			.ql-editor {
				padding: 25px 15px 10px;
				p {
					font-size: .9em;
					line-height: 1.5em;
					margin-bottom: 1em;
				}
				li {
					font-size: .9em;
					line-height: 1.5em;
				}
				h1 {
					font-size: 2.2em;
					line-height: 1.3em;
					margin-bottom: .8em;
					font-weight: 600;
				}
				h2 {
					font-size: 1.8em;
					line-height: 1.3em;
					margin-bottom: .8em;
					font-weight: 600;
				}
				h3 {
					font-size: 1.6em;
					line-height: 1.3em;
					margin-bottom: .8em;
					font-weight: 600;
				}
				h4 {
					font-size: 1.4em;
					line-height: 1.3em;
					margin-bottom: .8em;
					font-weight: 600;
				}
				h5 {
					font-size: 1.2em;
					line-height: 1.3em;
					margin-bottom: .8em;
					font-weight: 600;
				}
				h6 {
					font-size: 1em;
					line-height: 1.3em;
					margin-bottom: .8em;
					font-weight: 600;
				}
				ul,ol {
					padding-left: .5em;
				}
				strong {
					font-weight: 600;
				}
				em {
					font-style: italic;
				}
				sup {
					vertical-align: super;
					font-size: .7em;
					line-height: 1em;
				}
				sub {
					vertical-align: sub;
					font-size: .7em;
					line-height: 1em;
				}
			}
		}
	}
}

// =========================================/
// Dashboard
// =========================================/

.admin-dashboard {
	@include flex-container;
	@include admin-mq(m) {
		justify-content: center;
	}
	.ad-single {
		@include admin-white-background(30px, 15px 0px);
		width: 32%;
		position: relative;
		color: $color-black !important;
		text-decoration: none !important;
		@include transition(.2s);
		&:hover {
			box-shadow: 2px 2px 5px #a9a9a9;
		}
		@include admin-mq(l) {
			width: 48%;
		}
		@include admin-mq(m) {
			width: 95%;
		}
		h2 {
			margin: 0px 0px 20px;
		  font-size: 22px;
		  line-height: 28px;
		}
		form {
			font-size: 14px;
		}
		.ads-link {
			@include transition;
			width: 100px;
		  border: 2px solid $admin-color-bg-medium;
		  padding: 5px;
		  display: block;
		  text-align: center;
		  color: $admin-color-black;
		  margin: 20px 0px 0px 0px;
		  border-radius: 3px;
			font-size: 1em;
			&:hover {
				background-color: $admin-color-bg;
				border: 2px solid $admin-color-bg;
				color: $admin-color-white;
			}
			i {
				padding-left: 5px;
			}
		}
		.ads-ma-logo {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 100px;
			margin: 20px;
		}
	}
}

// =========================================/
// Login 
// =========================================/

.admin-container {
	padding: 50px 0px;
	@include global-width;
}
.admin-login-container {
	@include admin-white-background(0px, 0px auto 0px);
	width: 95%;
	max-width: 700px;
	display: block;
	.acl-header {
		@include flex-container;
		border-bottom: 1px solid $admin-color-bg-medium;
		p {
			width: 50%;
			text-align: center;
			font-size: 16px;
			padding: 25px 0px;
			background-color: $admin-color-bg-light;
			cursor: pointer;
		}
		p:nth-child(1){
			border-radius: 5px 0px 0px 0px;
		}
		p:nth-child(2){
			border-radius: 0px 5px 0px 0px;
			width: 51%;
		  margin-right: -1%;
		}
		.alch-active {
			background-color: $admin-color-white;
			width: 50% !important;
			margin-right: 0% !important;
		}
	}
	.alc-form-container {
		padding: 50px;
		h1 {
			font-weight: 900;
			margin: 0px 0px 20px;
		}
		label {
			display: block;
			font-size: 14px;
		}
		input {
			width: 100%;
		  padding: 8px;
		  border: 1px solid $admin-color-bg-medium;
		  margin: 5px 0px 10px;
		}
		input[type=submit] {
			@include transition;
			width: 150px;
			background-color: $admin-color-secondary;
			color: $admin-color-white;
			border: 0px;
			border-radius: 3px;
			display: inline-block;
			margin: 10px 10px 0px 0px;
			&:hover {
				background-color: $admin-color-secondary-dark;
			}
		}
		a {
			font-size: 13px;
		}
	}
	h1 {
		font-size: 22px;
		margin: 0px 0px 30px;
	}
	.alc-sign-out {
		text-align: center;
		font-size: 1.2em;
		margin: 0px;
		padding: 20px 0px;
	}
}

// =========================================/
// Footer
// =========================================/

.footer-admin {
	background-color: $admin-color-bg-light;
	padding: 30px 0px;
	display: none;
	.fa-container {
		@include global-width;
		a {
			color: $admin-color-primary;
			&:hover {
				color: $admin-color-secondary;
			}
			i {
				padding-right: 10px;
			}
		}
	}
}

// =========================================/
// Listing
// =========================================/

.admin-content-listing {
	@include flex-container;
	.acl-header {
		width: 100%;
		margin-bottom: 30px;
		a {
			display: inline-block;
			border: 2px solid #d3d3d3;
			padding: 8px 25px;
			color: #333;
			border-radius: 3px;
			&:hover {
				background-color: #333;
				color: #fff;
			}
		}
	}
	.atb-single {
		width: 32%;
		@include admin-white-background;
		margin: 0px 0px 20px;
		color: $color-black !important;
		text-decoration: none !important;
		@include transition(.2s);
		@include flex-container(space-between,center);
		&:hover {
			box-shadow: 0px 0px 10px #a9a9a9;
		}
		h1,h2 {
			margin: 0px;
			font-size: 24px;
			line-height: 30px;
		}
		p {
			font-size: 14px;
			font-weight: 400;
			margin: 0px;
			font-style: italic;
			color: gray;
			width: 100%;
			margin-top: 10px;
		}
		a {
			display: inline-block;
			border: 2px solid #d3d3d3;
			padding: 5px 15px;
			border-radius: 3px;
			color: #333;
			&:hover {
				background-color: #333;
				color: #fff;
			}
		}
	}
}
.atbs-header .atbsh-links a,
.admin-block-container .cancel-link,
.admin-block-container input[type=submit] {
  margin: 0px;
  border: 2px solid $admin-color-bg-medium;
  padding: 5px 15px;
  border-radius: 3px;
  color: $admin-color-black;
  line-height: 16px;
  margin-left: 3px;
  background: none;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}
.admin-block-container {
	.cancel-link {
		font-family: $admin-body-font;
	}
	.acul-delete {
		color: #333;
		font-size: 1em;
		&:hover {
			background-color: $admin-color-primary;
			border: 2px solid $admin-color-primary;
			color: $color-white;
		}
	}
}
.atbs-header .atbsh-links a:hover,
.admin-block-container .cancel-link:hover,
.admin-block-container input[type=submit]:hover {
	cursor: pointer;
	background-color: $admin-color-bg;
	border: 2px solid $admin-color-bg;
	color: $admin-color-white;
}

// =========================================/
// Vertical Listing
// =========================================/

.admin-vert-list {
	.avl-post {
		@include admin-white-background;
		display: none;
		h3 {
			font-size: 1.2em;
			font-weight: 600;
			margin-bottom: 15px;
		}
		p:first-child {
			font-weight: 600;
		}
		em {
			font-style: italic;
		}
		p:last-child {
			margin: 0px;
		}
	}
	.avlp-active {
		display: block;
	}
	.avl-admin-post {
		display: block;
		p {
			font-weight: 400 !important;
		}
		div {
			p {
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

// =========================================/
// Pages
// =========================================/

.admin-block-container {
	.atbs-header {
		margin-bottom: 20px;
		h2 {
			font-size: 1.5em;
			font-weight: 900;
			span {
				padding-left: 10px;
				font-size: .8em;
				font-style: italic;
				font-weight: 500;
			}
		}
	}
	.table-editor {
		border: 1px solid #d3d3d3;
		border-bottom: 0px;
		width: 100%;
		overflow-x: scroll;
		table {
			margin: 0px;
			border: 1px solid #d3d3d3;
			border-bottom: 0px;
			border-right: 0px;
			border-radius: 0px;
			width: 100%;
		}
		tr {
			display: flex;
			margin-top: -2px;
		}
		td {
			background-color: inherit !important;
			border: inherit !important;
			padding: 0px !important;
			margin-left: -2px;
			flex: 1;
			min-width: 200px;
			min-height: 100px;
		}
		textarea {
			border: 1px solid #d3d3d3;
			display: block;
			font-size: 14px;
			padding: 12px;
			width: 100%;
			height: 100%;
		}
	}
}
.table-actions span {
	display: inline-block;
  margin: 10px 10px 0px 10px;
  font-size: .9em;
  color: $admin-color-primary;
  cursor: pointer;
  &:hover {
  	text-decoration: underline;
  }
  i {
  	padding-right: 5px;
  }
}
.ac-block {
	@include admin-white-background;
	h2 {
		font-size: 1.2em;
		font-weight: 900;
		p {
			margin: 10px 0px 20px;
			font-size: 13px;
			font-style: italic;
			font-weight: 400;
		}
	}
	.atbs-editor {
		width: 100%;
	}
	.textarea-inline {
		width: 100%;
    border: 1px solid #a9a9a9;
    height: 80px;
    padding: 15px;
    font-size: .9em;
	}
	.input-inline {
		display: inline-block;
		font-size: .9em;
	}
	.date {
		margin-bottom: 10px;
		span {
			display: inline-block;
			font-weight: 900;
			margin: 0px 10px;
		}
		input {
			padding: 5px;
		}
	}
}
.atbs-footer {
	@include flex-container(flex-end);
	padding: 10px 0px;
}
.atbs-footer form {
	display: inline-block;
}

// =========================================/
// Images
// =========================================/

.acb-image,
.acb-images {
	position: relative;
	.acbi-active {
		height: 250px;
		width: 250px;
		background-size: 90%;
		background-position: center;
		background-repeat: no-repeat;
		background-color: #f2f2f2;
		border-radius: 5px;
		border: 2px solid #f2f2f2;
		&:hover {
			cursor: pointer;
			border: 2px solid gray;
		}
	}
	.acbi-container {
		flex-wrap: wrap;
		justify-content: space-between;
		display: none;
		.image-container {
			@include flex-container;
			width: 100%;
			.acbic-single {
				width: 24%;
				height: 150px;
				background-size: 90%;
				background-position: center;
				background-repeat: no-repeat;
				background-color: #f2f2f2;
				border-radius: 5px;
				margin: 5px 0px;
				border: 2px solid #f2f2f2;
				&:hover {
					cursor: pointer;
					border: 2px solid gray;
				}
			}
		}
	}
	.acbi-container-show {
		display: flex;
	}
}
.acbic-new {
	width: 100%;
	height: 100px;
	background-color: #f2f2f2;
	border-radius: 5px;
	margin: 10px 0px;
	border: 2px dashed $admin-color-bg-medium;
	cursor: pointer;
	@include flex-container(center, center);
	i {
		color: #a9a9a9;
		font-size: 28px;
	}
	p {
		margin: 0px !important;
	}
	.dropzone {
		height: 100%;
		@include flex-container(center, center);
		cursor: pointer;
		i {
			margin-top: 40px;
		  font-size: 30px;
		  color: $admin-color-bg-medium;
		}
		.dz-default {
			font-size: 13px;
		  width: 100%;
		  text-align: center;
		  margin-bottom: 30px;
		  color: gray;
		}
	}
	.dz-preview {
		display: none;
	}
}

// =========================================/
// Images Listing - (like for galleries)
// =========================================/

.acb-images {
	.acb-images-current {
		.select-image {
			cursor: pointer;
			background-color: $color-primary;
			color: #fff;
			border-radius: 5px;
			padding: 5px 15px;
			display: inline-block;
		}
		.acbi-active {
			height: 150px;
			width: 24%;
			display: inline-block;
			margin: 5px .5%;
			position: relative;
			i {
				position: absolute;
				top: 0;
				right: 0;
				color: $admin-color-primary;
				cursor: pointer;
				&:hover {
					color: $admin-color-secondary;
				}
			}
		}
	}
}

// =========================================/
// Sign out
// =========================================/

.admin-container .ac-signout {
	font-size: 18px;
	width: 100%;
	text-align: center;
	padding: 100px 0px;
}

// =========================================/
// Events
// =========================================/

.acbi-multiple {
	display: flex;
}
.acbi-multiple label {
	margin-right: 10px;
	font-size: 14px;
	width: 33%;
}
.acbi-multiple input {
	padding: 8px;
  margin-top: 5px;
  width: 100%;
}
.admin-content {
	.ac-new-event {
		@include transition;
		width: 200px;
		border: 2px solid $admin-color-bg-medium;
		padding: 15px;
		display: inline-block;
		text-align: center;
		color: $admin-color-black;
		border-radius: 3px;
		font-size: 1.1em;
		cursor: pointer;
		background-color: $admin-color-white;
		font-weight: 500;
		margin-bottom: 20px;
	}
}
.admin-content .ac-new-event:nth-child(2) {
	margin-left: 20px;
}
.admin-content .ac-new-event i {
	padding-left: 10px;
}
.admin-content .ac-new-event:hover {
	background-color: $admin-color-black;
	border: 2px solid $admin-color-black;
	color: $admin-color-white;
}
.ac-update-links {
	@include flex-container;
}
.ac-update-links a {
	margin: 0px;
  border: 2px solid $admin-color-bg-medium;
  padding: 5px 15px;
  border-radius: 3px;
  color: $admin-color-black;
  line-height: 16px;
  margin-left: 3px;
  background: none;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}
.ac-update-links a:hover {
	background-color: $admin-color-black;
	border: 2px solid $admin-color-black;
	color: $admin-color-white;
}
.ac-update-links span {
	font-size: .8em;
	margin-left: 20px;
	color: gray;
}

// =========================================/
// Meta
// =========================================/

.ac-block-image-auto textarea {
	display: none;
}
.input-count {
	margin: 0px;
	font-size: 12px;
	text-align: right;
	display: none;
}

// =========================================/
// Media
// =========================================/

.admin-media-container {
	.image-container {
		@include flex-container;
		.acbic-single {
			width: 24%;
			height: 200px;
			margin: 10px 0px;
			position: relative;
			background-position: center;
			background-size: 90%;
			background-repeat: no-repeat;
			border: 2px solid #f2f2f2;
			cursor: pointer;
			border-radius: 3px;
			&:hover {
				border: 2px solid $color-primary;
			}
		}
	}
}

// =========================================/
// Checkbox
// =========================================/

.checkbox-container {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  font-weight: 400;
  @include flex-container(normal, center);
  margin: 0px;
  height: 25px;
  input {
	  position: absolute;
	  opacity: 0;
	  cursor: pointer;
	  height: 0;
	  width: 0;
	  &:checked ~ .checkmark {
		  background-color: $admin-color-secondary;
		  &:after {
			  display: block;
			}
		}
	}
	.checkmark {
	  position: absolute;
	  top: 0;
	  left: 0;
	  height: 25px;
	  width: 25px;
	  background-color: #d3d3d3;
	  &:after {
		  content: "";
		  position: absolute;
		  display: none;
		  left: 9px;
		  top: 5px;
		  width: 5px;
		  height: 10px;
		  border: solid white;
		  border-width: 0 3px 3px 0;
		  -webkit-transform: rotate(45deg);
		  -ms-transform: rotate(45deg);
		  transform: rotate(45deg);
		}
	}
	&:hover input ~ .checkmark {
	  background-color: #ccc;
	}
}

// =========================================/
// Blog
// =========================================/

.hide-textarea {
	display: none;
}

// =========================================/
// Queries
// =========================================/

.admin-queries-container {
	@include flex-container(space-between, center);
	h2 {
		width: 100%;
	  font-weight: 900;
	  font-size: 18px;
	  @include flex-container;
	  a {
			font-size: 15px;
		}
	}
	.query {
		@include admin-white-background;
		width: 100%;
	  padding: 30px;
	  margin-top: 20px;
	  h3 {
			font-weight: 900;
			@include flex-container;
			margin-bottom: 15px;
			span {
				font-weight: 400;
				font-size: 16px;
			}
		}
		p {
			margin-bottom: 0px;
			font-weight: 900;
			span {
				font-weight: 400;
				padding-left: 5px;
			}
		}
	}
}

// =========================================/
// Admins
// =========================================/

.admin-admins-container {
	@include admin-white-background;
	@include flex-container(space-between, center);
	h2 {
		width: 100%;
	  font-weight: 900;
	  font-size: 18px;
	  @include flex-container;
	}
	.admin {
		width: 100%;
	  border-top: 1px solid #f2f2f2;
	  padding-top: 20px;
	  margin-top: 20px;
	  @include flex-container;
	  h3 {
			font-weight: 900;
			margin-bottom: 15px;
			width: 100%;
		}
		p {
			margin-bottom: 0px;
			font-weight: 900;
			span {
				font-weight: 400;
				padding-left: 5px;
			}
		}
		div {
			width: 150px;
			p {
				font-weight: 500;
				@include transition;
				@include admin-button(130px, 0px 0px 10px);
			}
			.aac-deny:hover {
				background-color: $admin-color-primary;
				border: 2px solid $admin-color-primary;
			}
			.aac-remove:hover {
				background-color: $admin-color-primary;
				border: 2px solid $admin-color-primary;
			}
		}
	}
}

// =========================================/
// Alerts
// =========================================/

.ac-block-input {
	select {
		display: block;
		font-weight: 300;
		font-size: 14px;
		height: 40px;
		font-family: $admin-body-font;
		width: 33%;
		background-color: $admin-color-white;
		border: 1px solid $admin-color-bg-medium;
	}
	input[type=color] {
		padding: 0px;
		border: 1px solid $admin-color-bg-medium;
		height: 30px;
	}
}

// =========================================/
// Resources
// =========================================/

.avl-tabs {
	@include flex-container(center);
  width: 100%;
  border-bottom: 1px solid $admin-color-bg-medium;
  p,a {
		margin: 0px 0px -1px;
		padding: 10px 20px;
		border-radius: 5px 5px 0px 0px;
		background-color: #f2f2f2;
		border-right: 1px solid $admin-color-bg-medium !important;
		border-bottom: 1px solid $admin-color-bg-medium !important;
		cursor: pointer;
    color: #333 !important;
    font-size: 14px;
    line-height: 22px;
    font-family: $admin-body-font;
    text-decoration: none !important;
	}
	.avct-active {
	  border: 1px solid #f2f2f2;
	  background-color: #fff;
	}
}
.avl-content {
	display: none;
	@include admin-white-background;
}
.avlc-active {
	display: block;
}
.avlc-post {
	margin: 20px 0px;
	border-bottom: 1px dashed #d3d3d3;
	padding-bottom: 8px;
	h3 {
		font-weight: 900;
		font-size: 1.1em;
		font-family: $admin-body-font;
		margin-bottom: 20px;
	}
	p {
		font-size: 13px;
		line-height: 21px;
		margin-bottom: 12px;
	}
}

// =========================================/
// Analytics
// =========================================/

.admin-analytics-container {
	h1 {
		font-size: 1.5em;
		margin-bottom: 30px;
	}
	.aac-single {
		@include admin-white-background;
		.aacs-tabs {
			display: flex;
			.aacst-tab {
				@include transition;
				padding: 8px 14px;
				font-size: 14px;
			  border-radius: 5px;
			  margin-right: 10px;
			  &:hover {
					background-color: #f2f2f2;
				}
			}
			.aacst-active {
				color: $admin-color-white !important;
				background-color: $admin-color-secondary !important;
			}
		}
		h2 {
			font-weight: 900;
		}
		.aacs-container {
			height: 300px;
			position: relative;
			.line {
				stroke: $admin-color-secondary;
				stroke-width: 3;
				fill: none;
			}
			.area {
				fill: $admin-color-primary-light;
				stroke: none;
			}
			.pie-container {
				position: absolute;
				top: 0;
				right: 0;
				p {
					position: relative;
					margin-bottom: 10px;
					span {
						height: 14px;
						width: 20px;
						position: absolute;
						top: 4px;
						left: -24px;
					}
				}
			}
		}
	}
}

// =========================================/
// Ordering
// =========================================/

.atb-order-container {
	background-color: #fff;
  box-shadow: 1px 1px 1px #d3d3d3;
  border-radius: 5px;
  padding: 30px;
  margin: 0px auto 20px auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  .atb-order-single {
  	@include flex-container(space-between, center);
  	width: 100%;
  	padding: 15px 0px;
  	border-bottom: 1px solid #d3d3d3;
  	p {
  		margin: 0px;
  	}
  	i {
  		cursor: pointer;
  		font-size: 1.5em;
  		margin-right: 20px;
  	}
  }
  .atb-order-single:last-child {
  	border-bottom: 0px;
  }
}

// =========================================/
// Super
// =========================================/

.admin-super-container {
	@include admin-white-background;
	h1 {
		width: 100%;
	  font-weight: 900;
	  font-size: 18px;
	}
	label {
		display: block;
		font-size: 1.1em;
		font-weight: 900;
		margin-bottom: 30px;
		input {
			display: block !important;
			width: 100%;
		  padding: 8px;
		  border: 1px solid $admin-color-bg-medium;
		  margin: 5px 0px 10px;
		  font-size: .9em;
		}
		select {
			display: block;
			width: 100%;
		  height: 37px;
		  border: 1px solid $admin-color-bg-medium;
		  margin: 5px 0px 10px;
		  font-size: .9em;
		}
	}
	input[type=submit] {
		@include transition;
		width: 150px;
		background-color: $admin-color-secondary;
		color: $admin-color-white;
		border: 0px !important;
		border-radius: 3px;
		display: inline-block;
		margin: 30px 0px 0px;
		&:hover {
			background-color: $admin-color-secondary-dark;
		}
	}
	.super-add {
		font-weight: 900;
		cursor: pointer;
		&:hover {
			color: $admin-color-primary;
		}
	}
	.asc-multiple {
		@include flex-container;
		label {
			width: 49%;
			margin-bottom: 0px;
			input {
				width: 100%;
			}
		}
	}
}

// =========================================/
// Quote
// =========================================/

.avl-tab-button {
	background-color: #fff;
	padding: 30px 30px 0px;
}
.export-quote-type {
	border: 2px solid #d3d3d3;
	border-radius: 3px;
	padding: 3px 12px;
	@include transition;
	display: inline-block;
	cursor: pointer;
	&:hover {
		background-color: #333;
		color: #fff;
		border: 2px solid #333;
	}
}
.quote-fields-header {
	@include flex-container(space-between,center);
	margin-bottom: 20px;
	p {
		margin: 0px;
	}
	.qfh-actions {
		span {
			border: 2px solid #d3d3d3;
			border-radius: 3px;
			padding: 3px 12px;
			@include transition;
			display: inline-block;
			margin-left: 10px;
			cursor: pointer;
			&:hover {
				background-color: #333;
				color: #fff;
				border: 2px solid #333;
			}
		}
	}
}
.quote-fields {
	@include flex-container;
	border-left: 1px solid #d3d3d3;
	border-top: 1px solid #d3d3d3;
	p {
		font-weight: 600;
    min-width: 50%;
    margin: 0px;
    border-right: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    padding: 10px;
		span {
			font-weight: 400;
			margin-left: 5px;
		}
	}
}