* {
	box-sizing: border-box;
}
html {
	scroll-behavior: smooth;
	@include animation(pageFade);
}
body {
	overflow-x: hidden;
}
@keyframes pageFade {
	0% {opacity: 0;}
	100% {opacity: 1;}
}
body,
textarea,
input {
	font-family: $font-stack-body;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 500;
	font-family: $font-stack-header;
}
.page {
	min-height: 100vh;
}
a {
	color: $color-primary;
	text-decoration: none;
}
a:hover {
	color: $color-secondary;
	text-decoration: none;
}
.ql-size-huge {
	font-size: 24px;
	line-height: 32px;
	font-weight: 500;
	margin: 20px 0px 10px;
	display: inline-block;
}
.ql-size-large {
	font-size: 20px;
	line-height: 28px;
	font-weight: 500;
	margin: 20px 0px 10px;
	display: inline-block;
}
.breadcrumbs {
	padding: 10px 0px;
	font-size: 14px;
}
.breadcrumbs span {
	margin: 0px 5px;
}
p {
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 16px;
}
li {
	font-size: 14px;
  line-height: 22px;
  margin-bottom: 5px;
}

// =========================================/
// Loading
// =========================================/

.loading {
	height: 80vh;
	@include flex-container(center,center);
}

// =========================================/
// Antispam
// =========================================/

.antispam {
	display: none;
}

// =========================================/
// Alerts
// =========================================/

.alert {
	position: relative;
}
.alert-close {
	position: absolute;
	top: 0;
	right: 0;
	margin: 12px;
	opacity: .5;
	font-size: 22px;
}
.alert-close:hover {
	cursor: pointer;
	opacity: 1;
}
.alert-center-container {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0,.2);
	display: flex;
	justify-content: center;
	align-items: center;
}
.alert-center {
  border-radius: 5px;
  padding: 40px;
  box-shadow: 1px 1px 1px $color-shade-dark;
  min-width: 400px;
  min-height: 250px;
  max-width: 90vw;
  max-height: 90vh;
}
.alert-top {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 30px;
}
.alert-bottom {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 30px;
}

// =========================================/
// Link Tooltip for External Links
// =========================================/

.new-tab {
	position: relative;
	&:hover:after {
		visibility: visible;
		opacity: 1;
		top: 130%;
	}
	&:after {
		content: 'Opens a new tab';
		@include transition(0.3s ease);
		white-space: nowrap;
	  position: absolute;
	  background-color: $color-black;
	  padding: 3px 10px;
	  z-index: 1000;
	  border-radius: 4px;
	  visibility: hidden;
	  opacity: 0;
	  -webkit-transform: translate(-50%, 0px);
	  -moz-transform: translate(-50%, 0px);
	  -ms-transform: translate(-50%, 0px);
	  -o-transform: translate(-50%, 0px);
	  transform: translate(-50%, 0px);
	  color: $color-white;
	  left: 50%;
	  top: 100%;
	  font-size: .8em;
	  line-height: 1.5em;
	}
}

// =========================================/
// Privacy Statement
// =========================================/

.privacy-footer {
	background-color: $color-black;
	padding: 20px;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	p {
		color: $color-white;
		margin: 0px;
		text-align: center;
		a {
			color: $color-white;
			font-weight: 900;
		}
		a:hover {
			text-decoration: underline;
		}
	}
	i {
    position: absolute;
    top: 0;
    right: 0;
    color: $color-white;
    margin: 8px;
    cursor: pointer;
	}
}
// IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.privacy-footer {display: none !important;}
}
