// =========================================/
// Footer
// =========================================/

footer {
	background-color: #f6f6f6;
	padding: 30px 0px 20px;
	border-top: 1px solid #d9d9d9;
	margin-top: 50px;
	.footer-container {
		@include global-width(1050px);
		.footer-top {
			@include flex-container(space-between,center);
			.ft-images {
				img {
					width: 80px;
					height: auto;
					margin-right: 5px;
				}
				@include mq(s) {
					width: 100%;
				}
			}
			.ft-content {
				width: calc(100% - 280px);
				@include mq(s) {
					display: none;
				}
				.ftc-top {
					border-bottom: 2px solid #d3d3d3;
					padding-bottom: 5px;
					margin-bottom: 15px;
					@include mq(l) {
						padding-bottom: 0px;
						border-bottom: 0px;
						margin-bottom: 0px;
					}
					a {
						border: 1px solid $color-primary;
						padding: 5px 8px;
						display: inline-block;
						font-size: .9em;
						border-radius: 3px;
						margin: 0px 10px 10px 0px;
						i {
							padding-left: 5px;
						}
					}
				}
				.ftc-main {
					@include flex-container;
					a {
						display: inline-block;
						font-size: .83em;
					}
					@include mq(l) {
						display: none;
					}
				}
			}
		}
		.footer-bottom {
			text-align: right;
			margin-top: 20px;
			p {
				display: inline-block;
				margin: 0px;
				font-size: .8em;
			}
			a {
				display: inline-block;
				margin-left: 10px;
				font-size: .8em;
				color: $color-black;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

// =========================================/
// COVID-19
// =========================================/

.covid {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0,.6);
	@include animation(covidFade,.5s);
	@include flex-container(center,center);
	z-index: 1;
	.container {
		background-color: #fff;
		max-width: 500px;
		position: relative;
		padding: 30px;
		border-radius: 3px;
		.close-covid {
			font-size: 2em;
			padding: 10px;
			position: absolute;
			top: -45px;
			right: -45px;
			cursor: pointer;
			@include mq(m) {
				top: 0;
				right: 0;
			}
			svg {
				fill: #fff;
				width: 20px;
				@include mq(m) {
					fill: $color-primary;
				}
			}
		}
		h6 {
			font-size: 1.5em;
			line-height: 1.2em;
			margin-bottom: 20px;
		}
		p {
			font-size: 1em;
			line-height: 1.5em;
		}
		p:last-child {
			margin-bottom: 0px;
		}
	}
}

@keyframes covidFade {
	0% { opacity: 0; }
	100% { opacity: 1; }
}